// Vuetify Documentation https://vuetifyjs.com

import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'


Vue.use(Vuetify)

export default new Vuetify({
    theme: {
        dark: false,
        themes: {
            light: {
                primary: '#4CAF50',
                secondary: '#424242',
                accent: '#82B1FF',
                error: '#FF5252',
                info: '#2196F3',
                success: '#1976D2',
                warning: '#FFC107',
            },
            dark: {
                primary: '#4CAF50',
                secondary: '#0B1C3D',
                accent: '#204165',
            },
        },
    },
})
